.toggle-check-input {
  -webkit-appearance: none;
  appearance: none;
  width: 55px;
  height: 20px;
  padding-left: 25px;
  margin: 0;
  border-radius: 16px;
  background: radial-gradient(circle 7px, white 100%, transparent calc(100% + 1px)) #ccc -16px;
  transition: 0.3s ease-in-out;
}

.toggle-check-input::before {
  content: 'NO';
  font: bold 11px Verdana;
  color: white;
}

.toggle-check-input:checked {
  padding-left: 8px;
  background-color: dodgerBlue;
  background-position: 16px;
}

.toggle-check-input:checked::before {
  content: 'YES';
}
