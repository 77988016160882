.file-manager__Table tbody tr {
  & .file-manager__Table--show-on-tr-hover {
    display: none;
  }
  &:hover .file-manager__Table--show-on-tr-hover {
    display: contents;
  }
}

.drag-on {
  text-decoration: underline !important;
  font-size: 15px !important;
}
